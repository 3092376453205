import { render, staticRenderFns } from "./Brand.vue?vue&type=template&id=50bf1855&scoped=true&"
import script from "./Brand.vue?vue&type=script&lang=js&"
export * from "./Brand.vue?vue&type=script&lang=js&"
import style0 from "./Brand.vue?vue&type=style&index=0&id=50bf1855&prod&lang=scss&"
import style1 from "./Brand.vue?vue&type=style&index=1&id=50bf1855&prod&lang=scss&scoped=true&"
import style2 from "./Brand.vue?vue&type=style&index=2&id=50bf1855&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50bf1855",
  null
  
)

export default component.exports